require('./bootstrap');


// Sweetalert2
window.Swal = require('sweetalert2');
window.Toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	onOpen: (toast) => {
    	toast.addEventListener('mouseenter', Swal.stopTimer);
    	toast.addEventListener('mouseleave', Swal.resumeTimer);
	}
});

// window.Vue = require('vue');

import fontawesome from '@fortawesome/fontawesome';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/*const app = new Vue({
    el: '#app',
});*/


$(document).ready(function() {
	$('[data-toggle="tooltip"]').tooltip();
    $('.select2').select2();
});

/*$(document).ready(function() {
    $('.datatable-button').DataTable({
        "lengthMenu": [ [5, 25, 50, -1], [5, 25, 50, "All"] ]
    });
});

$(document).ready(function() {
    $('.datatable').DataTable({
        buttons: true
    });
});
*/